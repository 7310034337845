<template>
  <div>
    <b-card
      no-body
      class="col-sm"
    >
      <driver-card-add-new
        :is-add-new-driver-card-sidebar-active.sync="isAddNewDriverCardSidebarActive"
        :driver-card-data="driverData"
        :is-modal-card-success.sync="isModalCardSuccess"
        :is-show-create-card.sync="isShowCreateCard"
      />

      <driver-view-driver-transfer
        :is-add-new-driver-transfer-sidebar-active.sync="isAddNewDriverTransferSidebarActive"
        :driver-transfer-data="driverData"
        :title="transferTitle"
        :cash-type="transferCashType"
        :type-transfer="transferType"
        :is-modal-transfer-success.sync="isModalTransferSuccess"
      />

      <b-modal
        id="modal-success-card"
        ok-only
        v-model="isModalCardSuccess"
        ok-variant="success"
        ok-title="Зрозуміло"
        modal-class="modal-success-card"
        centered
        title="Картку створено"
      >
        <b-card-text>
          Картку створено успішно! Очікуйте, коли вона зʼявиться у кабінеті 😊
        </b-card-text>
      </b-modal>

      <b-modal
        id="modal-success-balance"
        ok-only
        v-model="isModalTransferSuccess"
        ok-variant="success"
        ok-title="Зрозуміло"
        modal-class="modal-success-balance"
        centered
        title="Баланс змінено"
      >
        <b-card-text>
          Баланс змінено успішно! Очікуйте на кошти протягом декількох хвилин 😊
        </b-card-text>
      </b-modal>

      <b-card-body>
        <div class="row justify-content-end m-0">
          <!-- <div class="mb-2">
            <b-card-title>Картки</b-card-title>
            <b-card-sub-title>Паливні картки водія</b-card-sub-title>
          </div> -->
          <div class="mb-2">
            <b-button
              variant="primary"
              @click="isAddNewDriverCardSidebarActive = true"
              v-if="checkAllowedAccountTypes(driverData, driverData.wallet.accounts)"
            >
              <span class="text-nowrap">Додати картку</span>
            </b-button>
          </div>
        </div>
      </b-card-body>

      <b-card-body class="driver-view-cards raw">
          <div class="driver-view-card-wrapper" v-for="account in driverData.wallet.accounts" :key="account.id" >
            <DriverCard
                :account="account"
                :driverData="driverData"
                @showTransferModal="showTransferModal"
            />

          </div>
      </b-card-body>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, VBPopover, BCardText, BButton, BModal, BFormInput, BTooltip, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DriverCardAddNew from '../DriverCardAddNew.vue'
import DriverViewDriverTransfer from './DriverViewDriverTransfer.vue'
import { ref } from '@vue/composition-api'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import DriverCard from "@/views/apps/driver/drivers-view/driver-card/DriverCard.vue";
import store from "@/store";
import {useBusinessSettings} from "@/composition/businessSettings/useBusinessSettings";
export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BCardText,
    BButton,
    DriverCardAddNew,
    DriverViewDriverTransfer,
    BModal,
    BFormInput,
    BTooltip,
    BFormGroup,

    AppCollapse,
    AppCollapseItem,

    vSelect,

    DriverCard,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    driverData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    checkAllowedAccountTypes(data, accounts) {
      if (!this.accountTypes) {
        return false;
      }

      let isDriverStatusAllowToCreateRightNow = ['ACTIVE', 'CONFIRMED', 'PREPARED'].includes(data.status);
      if (!isDriverStatusAllowToCreateRightNow) {
        return false;
      }

      let collectedTypes = [];
      accounts.forEach(account => collectedTypes.push(account.typeAccount));

      let hasAnyTypeAccountAllowedToCreate = false;
      this.accountTypes.forEach(type => {
        if (!collectedTypes.includes(type)) {
          hasAnyTypeAccountAllowedToCreate = true;
        }
      });

      if (!hasAnyTypeAccountAllowedToCreate) {
        return false;
      }

      return true;
    }
  },
  setup(props) {
    const { accountTypes }  = useBusinessSettings();

    const isAddNewDriverCardSidebarActive = ref(false)
    let isAddNewDriverTransferSidebarActive = ref(false)

    let transferTitle = ref('')
    let transferType = ref('')
    let transferCashType = ref(0)

    const isModalTransferSuccess = ref(false)
    const isModalCardSuccess = ref(false)
    const isShowCreateCard = ref(true)

    const showTransferModal = (payload) => {
      isAddNewDriverTransferSidebarActive.value = true
      if (payload.direction == 'to') {
        transferTitle.value = 'Поповнити ' + (payload.typeAccount == 1 ? 'готівковий' : 'безготівковий') + ' баланс'
      } else {
        transferTitle.value = 'Зняти з ' + (payload.typeAccount == 1 ? 'готівкового' : 'безготівкового') + ' балансу';
      }
      transferCashType.value = payload.typeAccount
      transferType.value = payload.direction
    }

    return {
      isAddNewDriverCardSidebarActive,
      isAddNewDriverTransferSidebarActive,
      transferTitle,
      transferType,
      transferCashType,
      isModalTransferSuccess,
      isModalCardSuccess,
      isShowCreateCard,

      showTransferModal,
      accountTypes,
    }
  },
}
</script>

<style>

.button-balance {
  font-size: 16px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.driver-view-cards {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.driver-view-card {
  width: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* font-size: 10px; */
  margin-bottom: 40px;
}

.driver-view-card.empty {
  align-items: center;
  width: 200px;
  height: 125px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  color: var(--primary);
  font-size: 30px;
  font-weight: 800;
}

.driver-view-card:last-child {
  margin-bottom: 0;
}

.driver-view-card-image {
  width: 300px;
  height: 200px;
  border-radius: 15px;
  object-fit: cover;
  margin-right: 50px;
}

[dir] .table td {
  padding: 1rem 2rem;
}

.driver-view-card-balance {
  margin-top: 3px;
}

.driver-view-cards img {
  border-radius: 15px;
  width: 50%;
}

.driver-view-cards .card-body {
  padding: 2rem 0;
}

.fuel-checkbox-wrapper {
  width: 20%;
}

.card-inactive {
  padding: 5px;
  font-size: 14px;
  background: var(--danger);
  color: var(--white);
  border-radius: 10px;
  margin-left: 5px;
}

.limit-input {
  width: 80px;
  margin: 0 5px;
  text-align: center;
}

.limits-table {
  width: 100%;
}

.limits-table tr,
.limits-table tr input {
  font-size: 12px;
}

.limits-table th {
  background: #f8f8f8;
}

.dark-layout .limits-table th {
  background: #343d55;
}

.limits-table th,
.limits-table td {
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px solid #f8f8f8;
}

.dark-layout .limits-table th,
.dark-layout .limits-table td{
  border-bottom: 1px solid #343d55;
}

.block-card-icon {
  cursor: pointer;
}

.card-number {
  position: absolute; 
  top: 58px; 
  left: 13px;
  font-size: 12px;
}

.card-number.black {
  color: #000000;
}

.card-number.white {
  color: #FFFFFF;
}

.pin-input {
  width: 100px;
  height: 40px;
  margin-right: 12px;
}

.driver-view-card-wrapper .card-body {
  width: 100%;
  padding: 0 !important;
}

.driver-view-card-wrapper .card .card-header {
  background: #f8f8f8;
  border-radius: 0;
}

.dark-layout .driver-view-card-wrapper .card .card-header {
  background: #343d55;
}

.driver-view-card-wrapper .collapse-title {
  font-size: 16px !important;
}

@media screen and (max-width: 768px) {
  .driver-view-cards .card {
    flex-direction: column !important;
    width: 100%;
  }

  .driver-view-card {
    margin-bottom: 1rem !important;
  }

  .driver-view-card img {
    max-width: 50vw;
  }
}

</style>
