<template>
  <b-card
      :img-src="account.typeAccount == 1 ? '/cashcard.png' : account.typeAccount == 2 ? '/cashlesscard.png' : ''"
      class="driver-view-card"
      v-if="account.currentCard"
  >

    <div class="card-number" :class="{'white' : account.typeAccount == 1, 'black' : account.typeAccount == 2}">
      {{ account.currentCard.serialVisible }}
    </div>

    <CardStatus
        :account="account"
        :profile-id="driverData.profileId"
        :disabledAccount="disabledAccount"
    />

    <CardBalance
        :account="account"
        :status="driverData.status"
        :active-cash-types="driverData.activeCashTypes"
        :disabledAccount="disabledAccount"
        @showTransferModal="showTransferModal($event)"
    />

    <CardPin
        :account="account"
        :profile-id="driverData.profileId"
        :disabledAccount="disabledAccount"
    />

    <div v-if="isSelfPayAllowed" class="mt-2">
      <CardSelfPay
          :driver="driverData"
          :disabledAccount="disabledAccount"
      />
    </div>
    <div v-else class="mt-2" style="height: 20px"></div>

    <div v-if="isLimit">
      <CardLimits
          :account="account"
          :driver-data="driverData"
          :disabledAccount="disabledAccount"
      />
    </div>

    <CardFuelLimits
        :account="account"
        :driver-data="driverData"
        :disabledAccount="disabledAccount"
    />

    <app-collapse v-if="isDriverActivated && !isEndUser" class="mt-2">
      <app-collapse-item title="Активний шаблон знижки">
        <CardActiveDiscount :templateId="account.discount.current.templateId" />
      </app-collapse-item>
    </app-collapse>

    <app-collapse v-if="isDriverActivated && !isEndUser" class="mt-2">
      <app-collapse-item title="Персональні знижки">
        <CardPersonalDiscount
            :typeAccount="account.typeAccount"
            :profileId="driverData.profileId"
        />
      </app-collapse-item>
    </app-collapse>

    <app-collapse v-if="isDriverActivated && !isEndUser" class="mt-2">
      <app-collapse-item title="Пріорітет знижок">
        <CardDiscountsPriority :applicableDiscounts="account.discount.applicableDiscounts" />
      </app-collapse-item>
    </app-collapse>

  </b-card>
</template>

<script>

import {
  BCard,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import CardStatus from "@/views/apps/driver/drivers-view/driver-card/CardStatus.vue";
import CardBalance from "@/views/apps/driver/drivers-view/driver-card/CardBalance.vue";
import CardPin from "@/views/apps/driver/drivers-view/driver-card/CardPin.vue";
import CardSelfPay from "@/views/apps/driver/drivers-view/driver-card/CardSelfPay.vue";
import CardLimits from "@/views/apps/driver/drivers-view/driver-card/CardLimits.vue";
import CardFuelLimits from "@/views/apps/driver/drivers-view/driver-card/CardFuelLimits.vue";
import CardActiveDiscount from "@/views/apps/driver/drivers-view/driver-card/CardActiveDiscount.vue";
import CardPersonalDiscount from "@/views/apps/driver/drivers-view/driver-card/CardPersonalDiscount.vue";
import CardDiscountsPriority from "@/views/apps/driver/drivers-view/driver-card/CardDiscountsPriority.vue";

import { computed } from "@vue/composition-api";
import { useBusinessSettings } from "@/composition/businessSettings/useBusinessSettings";

export default {
  components: {
    BCard,

    AppCollapse,
    AppCollapseItem,

    CardStatus,
    CardBalance,
    CardPin,
    CardSelfPay,
    CardLimits,
    CardFuelLimits,
    CardActiveDiscount,
    CardPersonalDiscount,
    CardDiscountsPriority,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
      required: true,
    },
    driverData: {
      type: Object,
      required: true,
    },
  },
  emits: ["showTransferModal"],
  setup(props, {emit}) {

    const { accountTypes, isEndUser } = useBusinessSettings()
    const showTransferModal = (e) => {
      emit("showTransferModal", e);
    };

    const disabledAccount = computed(() => {
      return accountTypes.value ? !accountTypes.value.includes(props.account.typeAccount) : true;
    })

    const isSelfPayAllowed = computed(() => {
      return props.driverData.balanceType == 1 && props.account.typeAccount == 1
    })

    const isDriverActivated = computed(() => {
      const activatedStatuses = ['ACTIVE', 'CONFIRMED', 'PREPARED'];
      const disabledAccount = accountTypes.value ? !accountTypes.value.includes(props.account.typeAccount) : true;

      return activatedStatuses.includes(props.driverData.status) && !disabledAccount;
    });

    const isLimit = computed(() => {
      return props.account?.currentCard?.limits?.goods && props.account?.currentCard?.limits?.money
    })

    return { showTransferModal, disabledAccount, isDriverActivated, isEndUser, isLimit, isSelfPayAllowed };
  }
}

</script>
<style type="text/css">
  
</style>
