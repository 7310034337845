<template>
  <div class="d-flex align-items-center mt-1">
    <h4 class="mb-2">
      <span v-if="account.typeAccount == 1">Готівкова картка</span>
      <span v-if="account.typeAccount == 2">Безготівкова картка</span>
      <h5>{{ account.currentCard.cardName }}</h5>
    </h4>
    <h4 v-if="!account.isActive" class="mb-2 ml-1 card-inactive">Неактивна</h4>
    <div class="card-block-item" v-if="account.isActive" @click="blockCard(disabledAccount)">
      <feather-icon
          :id="`block-card-${account.id}`"
          icon="CheckCircleIcon"
          size="16"
          class="mx-1 mb-2 block-card-icon"
      />
      <b-tooltip
          :title="disabledAccount ? 'Опція недоступна' : 'Заблокувати картку'"
          :target="`block-card-${account.id}`"
      />
    </div>
    <div class="card-block-item" v-else @click="unblockCard(disabledAccount)">
      <feather-icon
          :id="`block-card-${account.id}`"
          icon="SlashIcon"
          size="16"
          class="mx-1 mb-2 block-card-icon"
      />
      <b-tooltip
          :title="disabledAccount ? 'Опція недоступна' : 'Розблокувати картку'"
          :target="`block-card-${account.id}`"
      />
    </div>
  </div>
</template>

<script>

import axiosIns from "@/libs/axios";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {BTooltip} from "bootstrap-vue";

export default {
  components: {
    BTooltip,
  },

  props: {
    account: {
      type: Object,
      default: () => {},
      required: true,
    },
    profileId: {
      type: String | Number,
      required: true,
    },
    disabledAccount: {
      type: Boolean,
      default: true
    },
  },
  setup(props) {
    const toast = useToast();

    const { account, profileId, disabledAccount } = props;
    const blockCard = async(disabled) => {
      if (disabled) {
        return
      }

      const response = await axiosIns
          .post(`/business/drivers/${profileId}/cards/${account.typeAccount}/block`)
          .then(() => {
            account.isActive = !account.isActive
            toast({
              component: ToastificationContent,
              props: {
                title: 'Збережено',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Помилка',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            return false
          })
    };

    const unblockCard = async (disabled) => {
      if (disabled) {
        return
      }

      const response = await axiosIns
          .post(`/business/drivers/${profileId}/cards/${account.typeAccount}/unblock`)
          .then(() => {
            account.isActive = !account.isActive
            toast({
              component: ToastificationContent,
              props: {
                title: 'Збережено',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Помилка',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            return false
          })
    };
    
    return { blockCard, unblockCard };
    
  }
}
</script>