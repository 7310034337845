<template>
  <app-collapse class="mt-2">

    <app-collapse-item title="Ліміти">

      <b-form-group
          label="Відображати ліміт у водія"
          for="displayableTypePeriod"
          class="mt-1"
      >
        <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="account.currentCard.limits.displayableTypePeriod"
            :options="displayableTypePeriodsOptions"
            class="w-100"
            :reduce="val => val.value"
            name="displayableTypePeriod"
            :clearable="false"
            :aria-disabled="disabledAccount"
            @input="(val) => account.currentCard.limits.displayableTypePeriod = val"

        />
      </b-form-group>

      <table v-if="goods && money" class="limits-table mt-1">
        <thead>
        <tr>
          <th></th>
          <th>Паливні</th>
          <th>Грошові</th>
        </tr>
        </thead>
        <tbody>

        <tr>
          <td>
            <b-form-checkbox
              class="custom-control-success"
              v-model="checkboxCheckedDay"
            >
              На день
            </b-form-checkbox>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ formatLimitPriceGoods(goods.dayLimitRest) }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="goods.dayLimitValue"
                  :disabled="periods.isDisabledDay"
                  @input="changeLimit('day', 'goods')"
              />
              <span>л</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ formatLimitPriceMoney(money.dayLimitRest) }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="money.dayLimitValue"
                  :disabled="periods.isDisabledDay"
                  @input="changeLimit('day', 'money')"
              />
              <span>грн</span>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <b-form-checkbox
              class="custom-control-success"
              v-model="checkboxCheckedWeek"
            >
              На тиждень
            </b-form-checkbox>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ formatLimitPriceGoods(goods.weekLimitRest) }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="goods.weekLimitValue"
                  :disabled="periods.isDisabledWeek"
                  @input="changeLimit('week', 'goods')"
              />
              <span>л</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ formatLimitPriceMoney(money.weekLimitRest) }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="money.weekLimitValue"
                  :disabled="periods.isDisabledWeek"
                  @input="changeLimit('week', 'money')"
              />
              <span>грн</span>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <b-form-checkbox
              class="custom-control-success"
              v-model="checkboxCheckedMonth"
            >
              На місяць
            </b-form-checkbox>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ formatLimitPriceGoods(goods.monthLimitRest) }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="goods.monthLimitValue"
                  :disabled="periods.isDisabledMonth"
                  @input="changeLimit('month', 'goods')"
              />
              <span>л</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ formatLimitPriceMoney(money.monthLimitRest) }} / </span>
              <b-form-input
                  class="limit-input"
                  v-model="money.monthLimitValue"
                  :disabled="periods.isDisabledMonth"
                  @input="changeLimit('month', 'money')"
              />
              <span>грн</span>
            </div>
          </td>
        </tr>

        </tbody>
      </table>

      <b-button
          :variant="disabledAccount ? '' : 'success'"
          :disabled="disabledAccount"
          @click="handleLimitsSave()"
      >
        Зберегти
      </b-button>
    </app-collapse-item>

  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import {BButton, BFormGroup, BFormInput, BFormCheckbox} from 'bootstrap-vue';
import vSelect from 'vue-select';
import {useSaveLimits} from '@/views/apps/driver/drivers-view/useSaveLimits';
import {onMounted, ref, watch} from '@vue/composition-api';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
      required: true,
    },
    driverData: {
      type: Object,
      required: true,
    },
    disabledAccount: {
      type: Boolean,
      default: true
    },
  },
  setup(props) {
    const displayableTypePeriodsOptions = [
      { label: 'За замовчуванням', value: 0 },
      { label: 'За день', value: 1 },
      { label: 'За тиждень', value: 2 },
      { label: 'За місяць', value: 3 },
      { label: 'За весь час', value: 4 },
    ];

    const formatLimitPriceGoods = (value) => {
      return parseInt(value / 1000);
    };

    const formatLimitPriceMoney = (value) => {
      return parseInt(value / 100);
    };

    const checkboxCheckedDay = ref(false);
    const checkboxCheckedWeek = ref(false);
    const checkboxCheckedMonth = ref(false);

    const watchCheckbox = (checkboxRef, propertyName) => {
      watch(checkboxRef, (newValue) => {
        periods.value[propertyName] = periods[propertyName] || !newValue;
      });
    };

    watchCheckbox(checkboxCheckedDay, 'isDisabledDay');
    watchCheckbox(checkboxCheckedWeek, 'isDisabledWeek');
    watchCheckbox(checkboxCheckedMonth, 'isDisabledMonth');

    const goods = ref(null);
    const money = ref(null);
    const periods = ref(null);

    onMounted(() => {
      const card = props.account.currentCard;
      const goodsLimits = card.limits.goods;
      const moneyLimits = card.limits.money;
      const periodsLimits = card.limits.periods;

      goods.value = {
        ...goodsLimits,
        dayLimitValue: formatLimitPriceGoods(goodsLimits.dayLimitValue),
        weekLimitValue: formatLimitPriceGoods(goodsLimits.weekLimitValue),
        monthLimitValue: formatLimitPriceGoods(goodsLimits.monthLimitValue),
      };

      money.value = {
        ...moneyLimits,
        dayLimitValue: formatLimitPriceMoney(moneyLimits.dayLimitValue),
        weekLimitValue: formatLimitPriceMoney(moneyLimits.weekLimitValue),
        monthLimitValue: formatLimitPriceMoney(moneyLimits.monthLimitValue),
      };

      periods.value = {...periodsLimits};

      checkboxCheckedDay.value = !card.limits.periods.isDisabledDay;
      checkboxCheckedWeek.value = !card.limits.periods.isDisabledWeek;
      checkboxCheckedMonth.value = !card.limits.periods.isDisabledMonth;
    });

    const changeLimit = (period, type) => {

      if (period === 'day') {
        if (type === 'goods') {
          money.value.dayLimitActive = false;
          money.value.dayLimitValue = 0;
          goods.value.dayLimitActive = true;
        } else if (type === 'money') {
          goods.value.dayLimitActive = false;
          goods.value.dayLimitValue = 0;
          goods.value.dayLimitRest = 0;
          money.value.dayLimitActive = true;
        }
      } else if (period === 'week') {
        if (type === 'goods') {
          money.value.weekLimitActive = false;
          money.value.weekLimitValue = 0;
          goods.value.weekLimitActive = true;
        } else if (type === 'money') {
          goods.value.weekLimitActive = false;
          goods.value.weekLimitValue = 0;
          goods.value.weekLimitRest= 0;
          money.value.weekLimitActive = true;
        }
      } else if (period === 'month') {
        if (type === 'goods') {
          money.value.monthLimitActive = false;
          money.value.monthLimitValue = 0;
          goods.value.monthLimitActive = true;
        } else if (type === 'money') {
          goods.value.monthLimitActive = false;
          goods.value.monthLimitValue = 0;
          goods.value.monthLimitRest = 0;
          money.value.monthLimitActive = true;
        }
      }
    };

    const { saveLimitsFuels } = useSaveLimits();

    const handleLimitsSave = () => {
      const limits = props.account.currentCard.limits;
      limits.goods = goods.value;
      limits.money = money.value;
      limits.periods = periods.value;
      saveLimitsFuels(
        limits,
        props.account.currentCard.fuels,
        props.driverData.profileId,
        props.account.typeAccount
      );
    };

    return {
      displayableTypePeriodsOptions,
      changeLimit,
      handleLimitsSave,
      formatLimitPriceGoods,
      formatLimitPriceMoney,
      goods,
      money,
      periods,
      checkboxCheckedDay,
      checkboxCheckedWeek,
      checkboxCheckedMonth,
    };
  }
};
</script>
<style type="scss">
  @media screen and (max-width: 768px) {
    .limit-input {
      padding: 1rem 0;
      max-width: 50%;
    }
  } 
</style>
