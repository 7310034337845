import axiosIns from '@/libs/axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {useToast} from 'vue-toastification/composition';

export function useSaveLimits() {

  const toast = useToast();
  const saveLimitsFuels = async (limits, fuels, id, typeAccount) => {

    const formatLimitPriceGoods = (value) => {
      return parseInt(value * 1000);
    };

    const formatLimitPriceMoney = (value) => {
      return parseInt(value * 100);
    };

    let body = {};
    if (limits.goods && limits.money) {
      let copyLimits = JSON.parse(JSON.stringify(limits));
      copyLimits.goods.dayLimitValue = formatLimitPriceGoods(copyLimits.goods.dayLimitValue);
      copyLimits.goods.weekLimitValue = formatLimitPriceGoods(copyLimits.goods.weekLimitValue);
      copyLimits.goods.monthLimitValue = formatLimitPriceGoods(copyLimits.goods.monthLimitValue);
      copyLimits.money.dayLimitValue = formatLimitPriceMoney(copyLimits.money.dayLimitValue);
      copyLimits.money.weekLimitValue = formatLimitPriceMoney(copyLimits.money.weekLimitValue);
      copyLimits.money.monthLimitValue = formatLimitPriceMoney(copyLimits.money.monthLimitValue);
      body.limits = copyLimits;
    }

    body.fuels = fuels;

    await axiosIns
      .put(`/business/drivers/${id}/cards/${typeAccount}`, body)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Збережено',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Помилка',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
        return false;
      });
  };

  return { saveLimitsFuels };
}
