<template>
  <div>
    <b-card v-if="templateId && discountTemplate" class="p-2">
      <b-card-title class="mb-1">{{ discountTemplate.title }}</b-card-title>
        <div v-for="issuer in discountTemplate.issuers" :key="issuer.id">
            <b-card-sub-title class="my-2">{{ getIssuerTitle(issuer.id) }}</b-card-sub-title>
            <hr/>
            <div v-for="discount in issuer.discounts" :key="discount.fuelType" class="my-1">
              <b-card-text class="my-0">
                Тип палива: <b>{{ discount.fuelType }}</b>
              </b-card-text>
              <b-card-text class="my-0">
                Політика знижки: <b>{{ getDiscountPolicyTypeTitle(discount.typeDiscountPolicyId) }}</b>
              </b-card-text>
              <b-card-text class="my-0">
                Знижка: <b>{{ discount.value }}</b>
              </b-card-text>
              <hr/>
            </div>
    </div>
    </b-card>
    <div v-else>Знижка видсутня</div>
  </div>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle, BCardText } from "bootstrap-vue";

import {useExtraIssuers} from "@/composition/extra/useExtraIssuers";
import {useExtraDiscountPolicyTypes} from "@/composition/extra/useDisountsPolicyTypes";
import {useDiscountTemplatesFetch} from "@/composition/discounts/useDiscountTemplatesFetch";
import {computed, onBeforeMount} from "@vue/composition-api";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  props: {
    templateId: {
      type: Number | null,
      required: true,
    }
  },
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BCardSubTitle,

    AppCollapse,
    AppCollapseItem,
  },
  setup(props) {
    const {getIssuerTitle} = useExtraIssuers();
    const {getDiscountPolicyTypeTitle} = useExtraDiscountPolicyTypes();
    const { fetchDiscountTemplates, discountTemplates,fetchAllDiscounts } = useDiscountTemplatesFetch();

    const discountTemplate = computed(() => {
      return discountTemplates?.value?.find(template => parseInt(template.id) === parseInt(props.templateId));
    });

    onBeforeMount(async () => {
      await fetchDiscountTemplates();
      await fetchAllDiscounts();
    });

    return {discountTemplate, getDiscountPolicyTypeTitle, getIssuerTitle}
  }
}
</script>