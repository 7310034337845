<template>
  <b-sidebar
    id="add-new-driver-card-sidebar"
    :visible="isAddNewDriverTransferSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-driver-transfer-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ title }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Повне імʼя"
          >
            <b-form-group
              label="Повне імʼя"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="driverTransferData.fullName"
                :state="getValidationState(validationContext)"
                placeholder="Іванов Іван Іванович"
                readonly
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Номер телефону"
          >
            <b-form-group
              label="Номер телефону"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="driverTransferData.phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="380681234567"
                readonly
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
              label="Сума"
              label-for="sum"
              class="mb-2"
            >
              <b-form-input
                id="sum"
                v-model="transferData.sum"
                trim
                placeholder=""
              />

          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ typeTransfer == 'to' ? 'Поповнити' : 'Зняти' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Відмінити
            </b-button>
          </div>
          <small class="text-danger mt-1 d-block" v-if="transferData.error">Неправильні дані або несумісний тип балансу</small>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormRadio,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDriverTransferSidebarActive',
    event: 'update:is-add-new-driver-transfer-sidebar-active',
  },
  props: {
    isAddNewDriverTransferSidebarActive: {
      type: Boolean,
      required: true,
    },
    isModalTransferSuccess: {
      type: Boolean,
      required: true,
    },
    driverTransferData: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    cashType: {
      type: Number,
      required: true,
    },
    typeTransfer: {
      type: String,
      required: true,  
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    
    const blankTransferData = {
      sum: '',
      error: '',
    }
    
    const transferData = ref(JSON.parse(JSON.stringify(blankTransferData)))
    const resetTransferData = () => {
      transferData.value = JSON.parse(JSON.stringify(blankTransferData))
    }

    const onSubmit = () => {

      let transferDataSend = {}

      if (props.typeTransfer == 'to') {

        transferDataSend = {
          typeAccount: props.cashType,
          to: props.driverTransferData.profileId,
          value: parseFloat(transferData.value.sum) * 100
        }

      } else if (props.typeTransfer == 'from') {

        transferDataSend = {
          typeAccount: props.cashType,
          from: props.driverTransferData.profileId,
          value: parseFloat(transferData.value.sum) * 100
        }

      }
      

      store.dispatch('app-driver/addTransfer', {
        transferData: transferDataSend
      })
        .then(response => {
          if (response.data.error) {
            transferData.value.error = true
            return
          }
          emit('update:is-modal-transfer-success', true)
          emit('refetch-data')
          emit('update:is-add-new-driver-transfer-sidebar-active', false)
        }).catch(() => {
          transferData.value.error = true
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetTransferData)

    return {
      transferData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-driver-card-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
