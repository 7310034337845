<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="driverData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching driver data
      </h4>
      <div class="alert-body">
        No driver found with this driver id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-drivers-list'}"
        >
          Driver List
        </b-link>
        for other drivers.
      </div>
    </b-alert>

    <template v-if="driverData">

      <driver-view-driver-info-card :driver-data="driverData" />

      <app-collapse class="mb-2">

        <app-collapse-item title="Картки" class="col-sm">
          <driver-view-driver-cards 
            :driver-data="driverData" 
            v-if="driverData.wallet.accounts.length >= 0"
          />
        </app-collapse-item>

      </app-collapse>

      <app-collapse class="mb-2">

        <app-collapse-item title="Балансові транзакції">
          <drivers-view-balances />
        </app-collapse-item>

      </app-collapse>

      <app-collapse class="mb-2">

        <app-collapse-item title="Паливні транзакції">
          <drivers-view-fuels />
        </app-collapse-item>

      </app-collapse>



    </template>

    <div v-else>loading...</div>

  </div>
</template>

<script>
import { computed, onBeforeMount } from '@vue/composition-api'
import { useRouter } from "@core/utils/utils";
import { BAlert, BLink } from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import FuelList from '@/views/apps/fuel/fuel-list/FuelList.vue'
import DriverViewDriverInfoCard from './DriverViewDriverInfoCard.vue'
import DriverViewDriverCards from './DriverViewDriverCards.vue'
import DriversViewBalances from './DriversViewBalances.vue'
import DriversViewFuels from './DriversViewFuels.vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {useStoreModule} from "@/composition/useStoreModule";
import {driversStoreModule} from "@/store/businessUsers/driversStore.module";
import {useDrivers} from "@/composition/businessUsers/useDrivers";

export default {
  components: {
    BAlert,
    BLink,
    vSelect,

    AppCollapse,
    AppCollapseItem,

    // Local Components
    DriverViewDriverInfoCard,
    DriverViewDriverCards,
    DriversViewBalances,
    DriversViewFuels,

    InvoiceList,
    FuelList,
  },
  setup() {
    const STORE_MODULE_NAME = 'businessUsers';

    const { route } = useRouter();

    useStoreModule(STORE_MODULE_NAME, driversStoreModule);
    const { fetchDrivers, drivers } = useDrivers();

    const driverData = computed(() => {
      const id = route?.value?.params.id;
      return drivers?.value?.find(driver => +driver.profileId === +id) || null;
    })

    onBeforeMount(() => {
      fetchDrivers();
    })

    return {
      driverData,
    }
  },
}
</script>

<style>

.collapse-title {
  font-size: 1.285rem !important;
  font-weight: 500 !important;
}

.collapse-default .card {
  border-radius: 0.428rem;
}

.collapse-default .card-header {
  user-select: none;
}

</style>
