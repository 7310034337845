<template>
  <b-card>

    <b-modal
      id="modal-contact"
      :hide-footer="true"
      centered
      title="Звʼязатись з водієм"
    >
      <b-form @submit.prevent="sendNotification()">
        <b-form-group
          label="Тема"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="title"
            trim
          />
        </b-form-group>
        
        <b-form-group
          label="Повідомлення"
          label-for="message"
        >
          <b-form-textarea
            id="message"
            v-model="message"
            trim
            style="height: 150px"
            required
          />
        </b-form-group>

        <b-form-checkbox
            v-model="important"
            value="important"
          >
            Важливо
        </b-form-checkbox>
        <div class="d-flex align-items-center mt-2 mb-1">
          <b-button
            variant="primary"
            class="mr-1"
            type="submit"
          >
            Надіслати
          </b-button>
          <small class="text-success" v-if="sended">Сповіщення надіслано</small>
          <small class="text-danger" v-if="error">Помилка. Сповіщення не надіслано</small>
        </div>
      </b-form>

    </b-modal>

    <b-row>

      <!-- Driver Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Driver Avatar & Action Buttons -->
        <div class="d-flex justify-content-start align-items-center">
          <b-avatar
            :src="driverData.avatar"
            :text="avatarText(driverData.fullName)"
            :variant="`light-${resolveUserBalanceVariant(driverData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ driverData.fullName }}
              </h4>
              <span class="card-text">{{ driverData.email }}</span>
            </div>
            <div class="row m-0">
              <b-button
                :to="{ name: 'apps-drivers-edit', params: { id: driverData.profileId } }"
                variant="primary"
                class="mr-1 mb-1"
              >
                Редагувати
              </b-button>
              <b-button
                variant="outline-secondary"
                v-b-modal.modal-contact
                class="mb-1 mr-1"
              >
                Звʼязатись
              </b-button>
              <b-button
                variant="danger"
                class="mb-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="blockDriver"
                v-if="driverData.status != 'BLOCKED'"
              >
                Заблокувати
              </b-button>
              <b-button
                variant="success"
                class="mb-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="unblockDriver"
                v-else-if="driverData.status == 'BLOCKED'"
              >
                Розблокувати
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Повне імʼя</span>
            </th>
            <td class="pb-50">
              {{ driverData.fullName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Номер телефону</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ driverData.phone }}
            </td>
          </tr>
          <tr v-for="group in driverData.groups" :key="group.id" v-if="group.id">
            <th class="pb-50">
              <feather-icon
                  icon="UsersIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Група</span>
            </th>
            <td class="pb-50 text-capitalize" >
              {{ group.title }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Статус</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ resolveUserStatus(driverData.status) }}
            </td>
          </tr>
          <tr v-if="driverData.confirmationCode">
            <th class="pb-50">
              <feather-icon
                icon="UnlockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Код підтвердження</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ driverData.confirmationCode }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UnlockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Тип рахунку</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ resolveUserBalance(driverData.balanceType) }}
            </td>
          </tr>
          <!-- 
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              {{ driverData.country }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ driverData.contact }}
            </td>
          </tr> -->
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BModal, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useDriversList from '../drivers-list/useDriversList'
import axiosIns from "@axios";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BModal, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox,
  },
  props: {
    driverData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async blockDriver() {

      const response = await axiosIns
        .post(`/business/drivers/${this.driverData.profileId}/block`)
        .then(() => {
          location.reload()
        })
        .catch(() => {
          this.toast({
              component: ToastificationContent,
              props: {
                  title: 'Error fetching users list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
          })
          return false
        })
    },
    async unblockDriver() {

      const response = await axiosIns
        .post(`/business/drivers/${this.driverData.profileId}/unblock`)
        .then(() => {
          location.reload()
        })
        .catch(() => {
          this.toast({
              component: ToastificationContent,
              props: {
                  title: 'Error fetching users list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
          })
          return false
        })
    },
    sendNotification() {

      axiosIns.post('/business/notifications/drivers', {
        profileIds: [this.driverData.profileId],
        title: this.title,
        message: this.message,
        type: this.important,
      })
      .then(response => {
        this.sended = true
        this.error = false
      })
      .catch(error => {
        this.sended = false
        this.error = true
      });
    },
  },
  setup() {

    const toast = useToast()

    const { resolveUserBalanceVariant, resolveUserStatus, resolveUserBalance } = useDriversList()

    const message = ''
    const title = ''
    const important = 'normal'
    const sended = false
    const error = false
    
    return {
      avatarText,
      resolveUserBalanceVariant,
      resolveUserStatus,
      resolveUserBalance,
      toast,

      message,
      title,
      important,
      sended,
      error,
    }
  },
}
</script>

<style>

</style>
