<template>
  <b-form-checkbox
      v-model="driver.isAllowSelfPay"
      class="custom-control-success"
      :disabled="disabledAccount"
      @change="changeIsAllowSelfPay"
  >
    Водій може самостійно поповнювати картку
  </b-form-checkbox>
</template>

<script>
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";
import { BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
  },
  props: {
    driver: {
      type: Object,
      required: true,
    },
    disabledAccount: {
      type: Boolean,
      default: true
    },
  },
  setup(props) {
    const toast = useToast();
    const { driver } = props;
    const changeIsAllowSelfPay = async () => {
      driver.isAllowSelfPay = !driver.isAllowSelfPay;

      return await axiosIns
          .put(`/business/drivers/${driver.profileId}`, {
            fullName: driver.fullName,
            isAllowSelfPay: driver.isAllowSelfPay,
          })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Збережено',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Помилка',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            return false
          })
    };

    return { changeIsAllowSelfPay };
  },
};
</script>