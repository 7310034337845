<template>
  <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
  >
    <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        class="bg-white p-2"
    >
      <validation-provider
          #default="validationContext"
          name="templateId"
          :rules="{required: true}"
      >
        <b-form-group
            v-if="mergedTemplates"
            id="input-group-1"
            label="Доступні шаблони"
            label-for="templateId"
            :state="getValidationState(validationContext)"
        >
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="templateId"
              label="title"
              :options="mergedTemplates"
              :reduce="val => val.id"
              name="templateId"
              placeholder="Оберіть шаблон"
          />
        </b-form-group>
        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <b-button
          @click.prevent="handleSubmit(onSubmit)"
          variant="primary"
      >
        Додати шаблон
      </b-button>

    </b-form>
  </validation-observer>
</template>

<script>
import { onBeforeMount, ref } from "@vue/composition-api";
import { useDiscountTemplatesFetch } from "@/composition/discounts/useDiscountTemplatesFetch";
import { useDiscountsTemplateChange } from "@/composition/discounts/useDiscountsTemplateChange";

import formValidation from "@core/comp-functions/forms/form-validation";

import { BForm, BFormGroup, BFormInvalidFeedback, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  props: {
    typeAccount: {
      required: true,
    },
    profileId: {
      required: true,
    },
  },
  components: {
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  setup(props) {
    const templateId = ref(null);

    const { fetchDiscountTemplates, fetchAllDiscounts, mergedTemplates } = useDiscountTemplatesFetch();
    const { refFormObserver, getValidationState } = formValidation();
    const { changeDiscountTemplate } = useDiscountsTemplateChange();

    const onSubmit = async () => {
      const params = {
        targetType: 3,
        typeAccount: props.typeAccount,
        templateId: templateId.value,
        profileIds: [props.profileId],
      };
      await changeDiscountTemplate(params);
      templateId.value = null;
    };

    onBeforeMount(() => {
      fetchDiscountTemplates();
      fetchAllDiscounts();
    });

    return {
      mergedTemplates,
      templateId,
      refFormObserver,
      getValidationState,
      onSubmit,
    };
  },
};
</script>