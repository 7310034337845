<template>
  <b-table
      :fields="tableColumns"
      :items="applicableDiscounts"
      responsive
      show-empty
      empty-text="Зеажки відсутні"
  >
    <template #cell(title)="data">
      <div class="font-weight-bold d-block text-nowrap">
        {{ getDiscountTemplatesTitle(data.item.discountTemplateId) || "Шаблон відсутній" }}
      </div>
    </template>
    <template #cell(target)="data">
      <div class="font-weight-bold d-block text-nowrap">
        {{ getTargetTypesTitle(data.item.typeTargetDiscount) }}
      </div>
    </template>
  </b-table>
</template>

<script>
import { BTable } from "bootstrap-vue";

import { useDiscountTemplatesFetch } from "@/composition/discounts/useDiscountTemplatesFetch";
import { useDiscountTargetTypes } from "@/composition/formOptions/useDiscountTargetTypes";
import {onBeforeMount} from "@vue/composition-api";

export default {
  props: {
    applicableDiscounts: {
      type: Array,
      default: []
    }
  },
  components: {
    BTable
  },
  setup() {
    const tableColumns = [
      { key: 'title', label: "Шаблон", sortable: false },
      { key: 'target', label: "Призначення", sortable: false },
    ];

    const { fetchDiscountTemplates, fetchAllDiscounts, getDiscountTemplatesTitle } = useDiscountTemplatesFetch();
    const { getTargetTypesTitle } = useDiscountTargetTypes();

    onBeforeMount(() => {
      fetchDiscountTemplates();
      fetchAllDiscounts();
    })

    return {
      tableColumns,
      getDiscountTemplatesTitle,
      getTargetTypesTitle,
    };
  }
}
</script>