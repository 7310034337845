<template>
  <div class="d-flex align-items-center">

    <h5 v-if="hasBalance">Баланс: {{ centsToDollars(account.balance) }} грн</h5>
    <div v-if="hasBalance && (isAllowDepositDriver || isAllowWithdrawalDriver)">
      <div class="d-flex ml-2">
        <b-button
            v-show="isAllowDepositDriver"
            class="mr-1 button-balance"
            variant="success"
            :disabled="isDepositDisabled"
            @click="showTransferModal(account.typeAccount, 'to')"
        >
          +
        </b-button>
        <b-button
            v-show="isAllowWithdrawalDriver"
            class="mr-1 button-balance"
            variant="danger"
            :disabled="isWithdrawalDisabled"
            @click="showTransferModal(account.typeAccount, 'from')"
        >
          <span class="text-nowrap">-</span>
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>
import {BButton} from "bootstrap-vue";
import { centsToDollars } from "@/helpers/priseConvert";

export default {
  components: {
    BButton
  },
  props: {
    account: {
      type: Object,
      default: () => {},
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    activeCashTypes: {
      type: Array,
      default: () => [],
    },
    disabledAccount: {
      type: Boolean,
      default: true
    },
  },
  emits: ["showTransferModal"],
  setup(props, { emit }) {
    const showTransferModal = (typeAccount, direction) => {
      emit("showTransferModal", {typeAccount, direction});
    };

    return { showTransferModal, centsToDollars }
  },
  computed: {
    hasBalance() {
      return !isNaN(this.account.balance);
    },
    hasPositiveBalance() {
      return this.hasBalance && this.account.balance > 0;
    },
    isActiveAccount() {
      return this.account.isActive;
    },
    isAllowDepositDriver() {
      /* temporary fix for find a reason && (account.typeAccount == 1 && activeCashTypes.includes(1) || account.typeAccount == 2 && activeCashTypes.includes(2)) */
      return ['ACTIVE', 'CONFIRMED', 'PREPARED'].includes(this.status);
    },
    isAllowWithdrawalDriver() {
      return ['ACTIVE', 'CONFIRMED', 'PREPARED', 'BLOCKED'].includes(this.status);
    },
    isDepositDisabled() {
      if (!this.isAllowDepositDriver) {
        return true;
      }

      if (!this.isActiveAccount) {
        return true;
      }

      return this.disabledAccount;
    },
    isWithdrawalDisabled() {
      if (!this.isAllowWithdrawalDriver) {
        return true;
      }

      if (this.hasPositiveBalance) {
        return false;
      }

      if (!this.isActiveAccount) {
        return true;
      }

      return this.disabledAccount;
    },
  }
}
</script>