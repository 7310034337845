<template>
  <div>
    <app-collapse class="mt-2">
      <app-collapse-item title="Доступні види палива">
        <div class="d-flex flex-wrap mt-1">

          <div class="mb-1 mr-3 fuel-checkbox-wrapper" v-for="fuel in fuels" :key="fuel.value">
            <b-form-checkbox
                class="custom-control-success"
                v-model="account.currentCard.fuels"
                :disabled="disabledAccount"
                :value="fuel.value"
            >
              {{ getFuelTitleShort(fuel.title) }}
            </b-form-checkbox>
          </div>

          <div class="mb-1 mr-3 fuel-checkbox-wrapper" v-for="fuel in filteredNotAllowed" :key="fuel">
            <b-form-checkbox
                class="custom-control-success"
                v-model="account.currentCard.fuels"
                :disabled="disabledAccount"
                :value="fuel"
            >
              {{ getFuelTitleShort(fuel) }}
            </b-form-checkbox>
          </div>

        </div>

        <b-button
            :variant="disabledAccount ? '' : 'success'"
            :disabled="disabledAccount"
            @click="saveLimitsFuels(account.currentCard.limits, account.currentCard.fuels, driverData.profileId, account.typeAccount)">
          Зберегти
        </b-button>
      </app-collapse-item>

    </app-collapse>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import {BButton, BFormCheckbox} from 'bootstrap-vue';
import {useSaveLimits} from '@/views/apps/driver/drivers-view/useSaveLimits';
import {useFuels} from '@/composition/extra/useFuels';

export default {
  components: {
    BFormCheckbox,
    BButton,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
      required: true,
    },
    driverData: {
      type: Object,
      required: true,
    },
    disabledAccount: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { saveLimitsFuels } = useSaveLimits();
    const { fuels, getFuelTitleShort } = useFuels();

    return { saveLimitsFuels, fuels, getFuelTitleShort };
  },
  methods: {
    checkIfContains(fuel) {
      for (let elem of this.fuels) {
        if (elem.value === fuel) {
          return true;
        }
      }

      return false;
    },
  },
  computed: {
    filteredNotAllowed() {
      return this.account.currentCard.fuels.filter(item => !this.checkIfContains(item));
    }
  },
};
</script>
